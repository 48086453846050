import React, { useState, useEffect } from 'react';
import * as styles from './filterSection.module.scss';

const FilterSection = ({
  authors,
  tags,
  onAuthorsSelectionChanged,
  onTagSelectionChanged,
  onSearchChange,
}) => {
  const [isVisible, setIsVisible] = useState(true);
  const [activeTag, setActiveTag] = useState(null);

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    return () => window.removeEventListener('scroll', listenToScroll);
  }, []);

  const listenToScroll = () => {
    let heightToHideFrom = getOffset(document.getElementById('filter-nav'));
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll < heightToHideFrom) {
      isVisible && setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  const getOffset = element => {
    const rect = element?.getBoundingClientRect(),
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return rect?.top + scrollTop;
  };

  function selectTag(tag) {
    setActiveTag(tag);
    onTagSelectionChanged(tag ? [tag] : []);
    goTo();
  }

  function isTagActive(tag) {
    return activeTag === tag;
  }

  function goTo() {
    const targetElement = document.getElementById('card-box-section');
    const navBarHeight = document.getElementById('filter-nav').clientHeight;

    const pos = targetElement.getBoundingClientRect();
    window.scroll({
      top: window.scrollY + pos.top - navBarHeight,
      behavior: 'smooth',
    });
  }

  return (
    <div id="filter-nav" className={[styles.filterNav, isVisible ? styles.active : ''].join(' ')}>
      <div className={styles.navWrap}>
        <ul className={styles.nav}>
          <li className={isTagActive(null) ? styles.active : ''}>
            <button onClick={() => selectTag(null)}>See All</button>
          </li>
          <li className={isTagActive('conviction-area') ? styles.active : ''}>
            <button onClick={() => selectTag('conviction-area')}>Conviction Areas</button>
          </li>
          <li className={isTagActive('for-founders') ? styles.active : ''}>
            <button onClick={() => selectTag('for-founders')}>For Founders</button>
          </li>
          <li className={isTagActive('portfolio-spotlights') ? styles.active : ''}>
            <button onClick={() => selectTag('portfolio-spotlights')}>Portfolio Highlights</button>
          </li>
          <li className={isTagActive('news') ? styles.active : ''}>
            <button onClick={() => selectTag('news')}>News</button>
          </li>
        </ul>
      </div>
      <label className={styles.form}>
        <input placeholder="Search" onChange={e => onSearchChange(e.target.value, goTo())}></input>
        <span className={styles.search}>
          <img src="/images/thoughts/search.svg" width="17" alt="" />
        </span>
      </label>
    </div>
  );
};

export default FilterSection;

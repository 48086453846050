// extracted by mini-css-extract-plugin
export var emcapBlog = "cardBoxSection-module--emcapBlog--3nFps";
export var list = "cardBoxSection-module--list--2-ivB";
export var container = "cardBoxSection-module--container--rgu5m";
export var lg = "cardBoxSection-module--lg--AGy6L";
export var cardThought = "cardBoxSection-module--cardThought--v_9-T";
export var imgHold = "cardBoxSection-module--imgHold--_F2Uz";
export var desc = "cardBoxSection-module--desc--2HTau";
export var authorRow = "cardBoxSection-module--authorRow--2gBRp";
export var info = "cardBoxSection-module--info--sJI1-";
export var name = "cardBoxSection-module--name--1XrFf";
export var post = "cardBoxSection-module--post--rhrYq";
export var catagory = "cardBoxSection-module--catagory--3_WM-";
export var orange = "cardBoxSection-module--orange--3FGj0";
export var purple = "cardBoxSection-module--purple--2Fs_u";
export var darkBlue = "cardBoxSection-module--dark-blue--1NOya";
export var brown = "cardBoxSection-module--brown--2_Uf3";
export var blue = "cardBoxSection-module--blue--2Vk8W";
export var green = "cardBoxSection-module--green--2hmGg";
export var readtime = "cardBoxSection-module--readtime--2ojYm";
import React, {useState} from 'react';
import * as PropTypes from 'prop-types';
import { uniqBy } from 'lodash';
import { graphql } from 'gatsby';
import Theme from '../../components/Theme';
import Seo from '../../components/SEO';
import Introduction from '../../components/ThoughtsPage/Introduction';
import ThoughtsCarousel from '../../components/ThoughtsPage/ThoughtsCarousel';
import Pagination from '../../components/Pagination';
import Newsletter from '../../components/Newsletter';
import GeneralLayout from '../../layouts/index';
import FilterSection from '../../components/ThoughtSections/FilterSection';
import CardBoxSection from '../../components/ThoughtSections/CardBoxSection';

const ThoughtsPageTemplate = ({ transition, data, pageContext }) => {
  const { allArticles, tags, index } = pageContext;
  const { contentfulThoughtsPage, contentfulNewsletter, site } = data;
  const { headline, headlineUnderline, featuredThoughts } = contentfulThoughtsPage;

  const [selectedAuthors, setSelectedAuthors] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [activeQuery, setActiveQuery] = useState("")
  const [visibleArticles, setArticles] = useState([...allArticles.slice((index-1)*10, 10)])
  const [pageCount, setPageCount] = useState(Math.ceil(allArticles.length / 10))
  const [currentPage, serCurrentPage] = useState(1)

  const allAuthors = allArticles.flatMap(article => [article.author, article.secondaryAuthor]).filter(el => el);
  const authors = uniqBy(allAuthors, 'slug');

  (typeof window !== 'undefined') && window.document.body.classList.add('thought-page');

  function onTagSelectionChanged(tags) {
    setSelectedTags(tags)
    filterArticles(tags, activeQuery, selectedAuthors)
  }

  function onAuthorsSelectionChanged(authors) {
    setSelectedAuthors(authors)
    filterArticles(selectedTags, activeQuery, authors)
  }

  function onSearchChange(query) {
    setActiveQuery(query)
    filterArticles(selectedTags, query, selectedAuthors)
  }

  function filterArticles(tags, query, authors, page = null) {
    if(!page) {
      page = 1
      serCurrentPage(page)
    }
    const filtered = allArticles.filter(article => {
      const articleTags = article.tags ? article.tags.map(tag => tag.slug) : []
      const articleAuthors = [article.author, article.secondaryAuthor].filter(e => e).map(author => author.slug)

      const queryFilterPassed = !query || (new RegExp(query, 'i')).test(article.title)
      const tagsFilterPassed = !tags.length || (tags.filter(tag => articleTags.includes(tag))).length > 0
      const authorsFilterPassed = !authors.length || (authors.filter(author => articleAuthors.includes(author))).length > 0

      return queryFilterPassed && tagsFilterPassed && authorsFilterPassed;
    })

    setPageCount(Math.ceil(filtered.length /10))

    const base = (page - 1) * 10
    setArticles([...filtered.slice(base, base + 10)])
  }
  function onPageSelected(page) {
    serCurrentPage(page)
    filterArticles(selectedTags, activeQuery, selectedAuthors, page)
  }

  return (
  <GeneralLayout>
    <Theme theme="emerald" transition={transition}>
      <Seo
        siteData={site}
        seoData={contentfulThoughtsPage.seo}
        defaultTitle="Emergence - Thoughts"
        pathName="/thoughts"
      />
      <Introduction headline={headline} headlineUnderline={headlineUnderline}>
        <FilterSection authors={authors} onAuthorsSelectionChanged={onAuthorsSelectionChanged}
                       tags={tags} onTagSelectionChanged={onTagSelectionChanged}
                       onSearchChange={onSearchChange}
        />
        <ThoughtsCarousel featuredThoughts={featuredThoughts} />
        {visibleArticles && <CardBoxSection articles={visibleArticles} />}
        {pageCount > 1 && <Pagination styleName={'blue'} index={currentPage} pageCount={pageCount} pathPrefix="thoughts" onPageSelected={onPageSelected} />}
      </Introduction>
      <Newsletter content={contentfulNewsletter} />
    </Theme>
  </GeneralLayout>
  );
};

ThoughtsPageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ThoughtsPageTemplate;

export const pageQuery = graphql`
  query thoughtsPageQuery {
    contentfulThoughtsPage {
      headline
      headlineUnderline
      ...FeaturedThoughtsQuery
      seo {
        customTitle
        metaDescription
        shareImage {
          file {
            url
          }
        }
      }
    }
    ...SiteQuery
    ...NewsletterQuery
  }
`;

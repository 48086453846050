import React from 'react';
import * as PropTypes from 'prop-types';

import PageHeader from '../../PageHeader';

import * as styles from './introduction.module.scss';

const Introduction = ({ headline, headlineUnderline, children }) => (
  <section className={styles.introduction}>
    <div className={styles.inner}>
      <PageHeader
        headline={headline}
        headlineUnderline={headlineUnderline}
        underlineTheme="canvasSalmon"
        theme="dark"
        url="portfolio"
      />
    </div>
    {children}
  </section>
);

export default Introduction;

Introduction.propTypes = {
  headline: PropTypes.string.isRequired,
  headlineUnderline: PropTypes.string,
};

import React from 'react';
import * as styles from '../../components/ThoughtSections/CardBoxSection/cardBoxSection.module.scss';
import AuthorImage from '../Author/Image';
import { get } from 'lodash';

const stripHTML = html => {
  return html ? html.replace(/(<([^>]+)>)/gi, '') : '';
};

const ThoughtsCard = ({ article }) => {
  const author = article.author;
  const secondaryAuthor = article.secondaryAuthor;
  const tagTitle = get(article, 'tags.0.name');

  return (
    <li>
      <a href={`/thoughts/${article.slug}`} className={styles.cardThought}>
        <div className={styles.desc}>
          {tagTitle && (
            <span className={[styles.catagory, styles.orange].join(' ')}>{tagTitle}</span>
          )}
          <h3>{stripHTML(article.title)}</h3>
          <span className={styles.readtime}>{article.timeToRead} min read</span>
          <p>{article.subTitleExcerpt}</p>
        </div>
        {author && (
          <div className={styles.authorRow}>
            <div className={styles.imgHold}>
              {author.portrait && (
                <AuthorImage
                  name={author.name}
                  url={author.portrait.fluid.src}
                  size="small"
                  slug={author.slug}
                  secondaryName={secondaryAuthor ? secondaryAuthor.name : ''}
                  secondaryUrl={
                    secondaryAuthor
                      ? secondaryAuthor.portrait && secondaryAuthor.portrait.fluid.src
                      : ''
                  }
                  secondarySlug={secondaryAuthor ? secondaryAuthor.slug : ''}
                  archived={author.archived}
                  secondaryArchived={secondaryAuthor && secondaryAuthor.archived}
                />
              )}
            </div>
            <div className={styles.info}>
              <strong className={styles.name}>{author.name}</strong>
              <span className={styles.post}>{author.title}</span>
              {secondaryAuthor && (
                <>
                  <strong className={styles.name}>{secondaryAuthor.name}</strong>
                  <span className={styles.post}>{secondaryAuthor.title}</span>
                </>
              )}
            </div>
          </div>
        )}
      </a>
    </li>
  );
};
export default ThoughtsCard;

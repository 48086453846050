import React from 'react';
import * as styles from './cardBoxSection.module.scss';

import ThoughtsCard from "../../ThoughtsCard"
import ThoughtsCardLarge from "../../ThoughtsCardLarge"

const CardBoxSection = ({articles}) => {

  return (
    <div id="card-box-section" className={styles.emcapBlog}>
      <div className={styles.container}>
        <ul className={styles.list}>
          {articles && articles.map((article, i) => {
            const ThoughtsCardComponent = article.secondaryAuthor ? ThoughtsCardLarge : ThoughtsCard
            return (
              <ThoughtsCardComponent key={i} article={article}></ThoughtsCardComponent>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default CardBoxSection;

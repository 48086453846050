import React from 'react';
import * as styles from '../../components/ThoughtSections/CardBoxSection/cardBoxSection.module.scss';
import AuthorImage from '../Author/Image';
import { get } from 'lodash';

const stripHTML = html => {
  return html ? html.replace(/(<([^>]+)>)/gi, '') : '';
};

const ThoughtsCardLarge = ({ article }) => {
  const author = article.author;
  const secondaryAuthor = article.secondaryAuthor;
  const tagTitle = get(article, 'tags.0.name');

  return (
    <li className={styles.lg}>
      <a href={`/thoughts/${article.slug}`} className={styles.cardThought}>
        <div className={styles.desc}>
          {tagTitle && (
            <span className={[styles.catagory, styles.orange].join(' ')}>{tagTitle}</span>
          )}
          <h3>{stripHTML(article.title)}</h3>
          <span className={styles.readtime}>{article.timeToRead} min read</span>
          <p>{article.subTitleExcerpt}</p>
        </div>
        {author && (
          <div className={styles.imgHold}>
            {author.portrait && (
              <AuthorImage
                name={author.name}
                url={author.portrait.fluid.src}
                slug={author.slug}
                secondaryName={secondaryAuthor ? secondaryAuthor.name : ''}
                secondaryUrl={
                  secondaryAuthor
                    ? secondaryAuthor.portrait && secondaryAuthor.portrait.fluid.src
                    : ''
                }
                secondarySlug={secondaryAuthor ? secondaryAuthor.slug : ''}
                archived={author.archived}
                secondaryArchived={secondaryAuthor && secondaryAuthor.archived}
              />
            )}
            <p>
              <b>{author.name}</b>, {author.title}
            </p>
            {secondaryAuthor && (
              <p>
                <b>{secondaryAuthor.name}</b>, {secondaryAuthor.title}
              </p>
            )}
          </div>
        )}
      </a>
    </li>
  );
};
export default ThoughtsCardLarge;

// {author && <div className={styles.imgHold}>
//   {author.portrait && <img src={author.portrait.fluid.src} alt={author.name}/>}
//   <p><b>Julie Bell</b>, Chief Operating Officer</p>
//   <p><b>Tammy Han</b>, Head of Talent</p>
// </div>}

import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Slider from 'react-slick';
import * as PropTypes from 'prop-types';
import ArticleCard from '../../ArticleCard';

import * as counterStyles from '../../CarouselCounter/carouselCounter.module.scss';
import * as styles from './thoughtsCarousel.module.scss';
import '../../../styles/vendor/slick-thoughts-slider.scss';

class ThoughtsCarousel extends Component {
  state = {
    activeSlide: 1,
  };

  createSlickSettings = () => {
    return {
      beforeChange: (current, next) => this.setState({ activeSlide: next + 1 }),
      infinite: true,
      arrows: true,
      speed: 500,
      variableWidth: true,
      slidesToScroll: 1,
      draggable: false,
      autoplay: true,
      autoplaySpeed: 5000,
      responsive: [
        {
          breakpoint: 770,
          settings: {
            draggable: true,
          },
        },
      ],
    };
  };

  render() {
    const { activeSlide } = this.state;
    const { featuredThoughts } = this.props;
    const slickSettings = this.createSlickSettings();

    return (
      <div className={['slick-thoughts-slider', styles.slider].join(' ')}>
        <div className={styles.inner}>
          <div className={counterStyles.counter}>
            <span className={'counterCurrent'}>{activeSlide}</span>
            <span className={counterStyles.counterTotal}>{featuredThoughts.length}</span>
          </div>
          <Slider {...slickSettings}>
            {featuredThoughts &&
              featuredThoughts.map((thought, i) => {
                return (
                  <div className={[styles.slide, 'slick-article-card-wrapper'].join(' ')} key={i}>
                    <ArticleCard article={thought} featuredArticle={true} />
                  </div>
                );
              })}
          </Slider>
        </div>
      </div>
    );
  }
}

ThoughtsCarousel.propTypes = {
  featuredCompanies: PropTypes.array,
};

export default ThoughtsCarousel;

export const query = graphql`
  fragment FeaturedThoughtsQuery on ContentfulThoughtsPage {
    featuredThoughts {
      title
      subtitle
      slug
      publishedDate(formatString: "MM.DD.YYYY")
      author {
        name
        slug
        title
        portrait {
          fluid {
            src
          }
        }
        archived
      }
      secondaryAuthor {
        name
        slug
        title
        portrait {
          fluid {
            src
          }
        }
        archived
      }
      tags {
        ... on ContentfulTag {
          name
          slug
        }
        ... on ContentfulThesis {
          name
          slug
        }
      }
    }
  }
`;

import React from 'react';
import { Link } from 'gatsby';
import {inRange, uniq} from 'lodash';
import * as PropTypes from 'prop-types';

import * as styles from './pagination.module.scss';

const Pagination = ({ index, pageCount, pathPrefix, styleName, onPageSelected = null}) => (
  <div className={[styles.wrapper, styles[styleName]].join(' ')}>
    <div className={styles.inner}>
      <div className={styles.pagination}>{buildPagination(index, pageCount, pathPrefix, onPageSelected)}</div>
    </div>
  </div>
);

function buildPagination(index, pageCount, pathPrefix, onPageSelected) {
  let pagination = [];

  const checkRange = pageIndex => inRange(pageIndex, index - 3, index + 3);

  const currentPage = pageIndex => (
    <span key={pageIndex} className={[styles.page, styles.current].join(' ')}>
      {pageIndex}
    </span>
  );

  const onClick = (event, index) => {
    if(!onPageSelected) return
    event.preventDefault();
    onPageSelected(index)
  }

  const pageLink = (pageIndex, className) => (
    <span key={pageIndex} className={[styles.page, styles[className]].join(' ')}>
      <Link onClick={(event) => onClick(event, pageIndex)}
        className={styles.link}
        to={
          pageIndex === 1
            ? !pathPrefix
              ? ''
              : `/${pathPrefix}`
            : !pathPrefix
            ? `${pathPrefix}/${pageIndex}`
            : `/${pathPrefix}/${pageIndex}`
        }>
        {pageIndex}
      </Link>
    </span>
  );

  for (let pageIndex = 1; pageIndex <= pageCount; pageIndex++) {
    if (checkRange(pageIndex)) {
      pagination.push(pageIndex === index ? currentPage(pageIndex) : pageLink(pageIndex));
    }
  }

  if (!checkRange(1)) {
    pagination.unshift(pageLink(1, 'first'));
  }

  if (!checkRange(pageCount)) {
    pagination.push(pageLink(pageCount, 'last'));
  }

  return uniq(pagination);
}

Pagination.propTypes = {
  index: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  pathPrefix: PropTypes.string.isRequired,
  styleName: PropTypes.string
};

export default Pagination;
